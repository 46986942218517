<template>
  <div class="py-10">
    <v-container fluid class="px-10">
      <v-row justify="center">


        <v-dialog v-model="showDialogCrear" persistent max-width="800px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="cerrarCrearSolicitud">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Crear Reporte sin límite de Documentos</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" justify="center">
                  <date-range-picker ref="dateRange" v-model="selectedDatesIn"></date-range-picker>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="rfcSearchIn" counter="0" label="RFC" class="my-1" maxlength="13">
                  </v-text-field>
                </v-col>
                <v-col md="8" justify="center">
                  <v-text-field v-model="descripcionIn" counter="0" label="Descripción*" class="my-1" maxlength="100">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="requestItems" text large :disabled="!showCreateButton">Solicitar Reporte
              </v-btn>
              <v-btn color="danger" text @click="cerrarCrearSolicitud">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogEliminarReport = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Confirmación Eliminar Reporte:
                {{ this.itemDesc }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <br />
              <h3>Este reporte sera eliminado permanentemente</h3>
              <h3>¿Está seguro de continuar con esta acción?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
              <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogReports" persistent>
          <v-col cols="12">
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="showDialogReports = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Reportes Solicitados</v-toolbar-title>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="ObtenerReportes" icon v-on="on">
                      <v-icon>mdi-cached</v-icon>
                    </v-btn>
                  </template>
                  <span>Refrescar Tabla</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-btn @click="showDialogCrear = true" large color="second">
                  <v-icon>mdi-plus</v-icon>Solicitar Reporte
                </v-btn>
              </v-toolbar>

              <v-row>
                <v-col cols="12">
                  <v-alert :value="alert_errorIn" type="error" transition="scale-transition">{{ errorMessageIn }}
                  </v-alert>
                  <v-alert :value="alert_okIn" type="success" transition="scale-transition">{{ okMessageIn }}</v-alert>
                </v-col>
                <v-col cols="12">
                  <v-simple-table height="600" fixed-header dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Descripción</th>
                          <th>Fecha Solicitud</th>
                          <th>Fecha Inicial</th>
                          <th>Fecha Final</th>
                          <th>RFC</th>
                          <th>Mensaje</th>
                          <th>Resultado</th>

                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in itemsGrandes" :key="item.id" class="altura">
                          <td>
                            {{ item.description }}
                          </td>
                          <td>
                            {{ item.requestDate }}
                          </td>
                          <td>
                            {{ item.commands.dateInit }}
                          </td>
                          <td>
                            {{ item.commands.dateEnd }}
                          </td>
                          <td>
                            {{ item.commands.taxid }}
                          </td>

                          <td>
                            {{ item.message }}
                          </td>
                          <td>
                            {{
                              item.ready
                              ? item.result
                                ? "Exitoso"
                                : "Fracasado"
                              : "Procesando"
                            }}
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left v-if="item.ready && item.result">
                              <template v-slot:activator="{ on }">
                                <v-btn @click="DescargarReporte(item.id)" icon color="#4aa832" v-on="on">
                                  <v-icon>mdi-download</v-icon>
                                </v-btn>
                              </template>
                              <span>Descargar Reporte</span>
                            </v-tooltip>
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left v-if="item.ready">
                              <template v-slot:activator="{ on }">
                                <v-btn @click="ConfirmarEliminarReporte(item)" icon color="red" v-on="on">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Eliminar Reporte</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-dialog>


        <v-dialog v-model="showDialog" persistent max-width="1000px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Complemento del documento:
                {{
                  itemSelected.folio_fiscal == null
                  ? itemSelected.id
                  : itemSelected.folio_fiscal
                }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-card class="ma-3 pa-2">
                <div class="body-1">
                  Razón Social: {{ itemSelected.nombre }}
                </div>
                <div class="body-1">RFC: {{ itemSelected.rfc }}</div>
                <div class="body-1">CURP: {{ itemSelected.curp }}</div>
                <div class="body-1">
                  Tipo Regimen: {{ itemSelected.tipo_regimen }}
                </div>
                <div class="body-1">
                  Tipo Nomina: {{ itemSelected.tipo_nomina }}
                </div>
              </v-card>
              <br />
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Fecha Emisión</th>
                      <th>Fecha Timbre</th>
                      <th>Fecha Pago</th>
                      <th>Fecha Inicio Pago</th>
                      <th>Fecha Final Pago</th>
                      <th>Dias Pagados</th>
                      <th>Periodicidad</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="altura">
                      <td>{{ itemSelected.fecha_emision }}</td>
                      <td>{{ itemSelected.fecha_timbre }}</td>
                      <td>{{ itemSelected.fecha_pago }}</td>
                      <td>{{ itemSelected.fecha_inicio_pago }}</td>
                      <td>{{ itemSelected.fecha_final_pago }}</td>
                      <td>{{ itemSelected.numero_dias_pagados }}</td>
                      <td>{{ itemSelected.periodicidad_pago }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <br />
              <p class="font-weight-black">Totales:</p>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Sueldos</th>
                      <th>Gravado</th>
                      <th>Exento</th>
                      <th>Otras Deducciones</th>
                      <th>Impuestos Retenidos</th>
                      <th>Percepciones</th>
                      <th>Deducciones</th>
                      <th>Otros Pagos</th>
                      <th>Separación Indemnización</th>
                      <th>Jubilación</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="altura">
                      <td align="right">
                        {{ currencyFormat(itemSelected.total_sueldos) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelected.total_gravado) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelected.total_exento) }}
                      </td>
                      <td align="right">
                        {{
                          currencyFormat(itemSelected.total_otras_deducciones)
                        }}
                      </td>
                      <td align="right">
                        {{
                          currencyFormat(itemSelected.total_impuestos_retenidos)
                        }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelected.total_percepciones) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelected.total_deducciones) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemSelected.total_otros_pagos) }}
                      </td>
                      <td align="right">
                        {{
                          currencyFormat(
                            itemSelected.total_separacion_indemnizacion
                          )
                        }}
                      </td>
                      <td align="right">
                        {{
                          currencyFormat(
                            itemSelected.total_jubilacion_pension_retiro
                          )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="showDialog = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>

        <v-col cols="12">
          <h3>Reporte de Complemento de Nómina

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/reporte-de-complemento-de-nomina"
                  target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
        </v-col>
        <v-col>
          <v-card class="paddingCard">
            <v-row>
              <v-col cols="12" md="4" class="px-2 mx-md-10">
                <date-range-picker v-model="selectedDates"></date-range-picker>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-text-field v-model="rfcSearch" counter="0" label="RFC" class="min-width-control-xl"
                  maxlength="13"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                <v-btn color="primary" white large v-on:click="getReport" :disabled="!showSearchButton">Mostrar Listado
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                <v-tooltip top>
                  <template v-slot:activator="{ on }" class="d-none d-sm-flex">
                    <v-btn color="primary" white large v-on="on" v-on:click="ObtenerReportes">Reportes Bajo Demanda
                    </v-btn>
                  </template>
                  <span class="d-none d-sm-flex">Reportes Solicitados Grandes</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
          <br />
          <v-card v-if="showCantidades">
            <v-row>
              <v-col cols="12" md="2">
                <v-card class="mx-5" min-width="300" max-width="300" tile>
                  <v-app-bar dark color="second">
                    <v-toolbar-title>Total Sueldos</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-card-text class="text--primary">
                    <h4>
                      ${{ currencyFormat(total_sueldos) }} MXN
                    </h4>

                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="2">
                <v-card class="mx-5" min-width="300" max-width="300" tile>
                  <v-app-bar dark color="second">
                    <v-toolbar-title>Total Gravado</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-card-text class="text--primary">
                    <h4>
                      ${{ currencyFormat(total_gravado) }} MXN
                    </h4>

                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="2">
                <v-card class="mx-5" min-width="300" max-width="300" tile>
                  <v-app-bar dark color="second">
                    <v-toolbar-title>Total Exento</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-card-text class="text--primary">
                    <h4>
                      ${{ currencyFormat(total_exento) }} MXN
                    </h4>

                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="2">
                <v-card class="mx-5" min-width="300" max-width="300" tile>
                  <v-app-bar dark color="second">
                    <v-toolbar-title>Total Otras Deducciones</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-card-text class="text--primary">
                    <h4>
                      ${{ currencyFormat(total_otras_deducciones) }} MXN
                    </h4>

                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="2">
                <v-card class="mx-5" min-width="300" max-width="300" tile>
                  <v-app-bar dark color="second">
                    <v-toolbar-title>Total Impuestos Retenidos</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-card-text class="text--primary">
                    <h4>
                      ${{ currencyFormat(total_impuestos_retenidos) }} MXN
                    </h4>

                  </v-card-text>
                </v-card>
              </v-col>


            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de
              {{ totalDocumentos }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-icon class="d-none d-sm-flex" @click="getReporteNominaCSV" :disabled="totalDocumentos > 5000"
              color="second">mdi-file-export</v-icon>

            <v-toolbar-items class="pa-3">
              <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations" menu-props="auto"
                label="Total de Registros" hide-details class="ma-3 anchoP"></v-select>
              <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large color="second">
                    mdi-arrow-up-bold-circle-outline</v-icon>
                </template>
                <span>Página anterior</span>
              </v-tooltip>
              <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

              <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                    mdi-arrow-down-bold-circle-outline</v-icon>
                </template>
                <span>Página siguiente</span>
              </v-tooltip>
              <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline</v-icon>

              <v-btn text>
                <v-text-field type="number" min=1 :max="totalPaginas" v-model="goPage" class="mr-1 mb-0 anchoGuia"
                  @keyup.enter.native="goToPage"></v-text-field>
                <span class="tamPagina">/{{ totalPaginas }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>


            <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
              :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
              :frozenColumns="1" :initialized="initGrid" :allowResizing="'None'">

              <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'folio_fiscal'" width="4*" :isReadOnly="true" />

              <wj-flex-grid-column  :header="'RFC Receptor'" :binding="'rfc'" width="2*"
                :isReadOnly="true" />
              <wj-flex-grid-column  :header="'Razón Social'" :binding="'nombre'" width="4*"
                :isReadOnly="true" />
              
              <wj-flex-grid-column :header="'Fecha Pago'" :binding="'fecha_pago'" width="2*" :isReadOnly="true" />

              
              <wj-flex-grid-column :header="'Total Sueldos'" :binding="'total_sueldos'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total Gravado'" :binding="'total_gravado'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total Exento'" :binding="'total_exento'" width="2*" format="c2"
                :isReadOnly="true" />
                <wj-flex-grid-column :header="'Total Otras Deducciones'" :binding="'total_otras_deducciones'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total Impuestos Retenidos'" :binding="'total_impuestos_retenidos'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="verMasDatos(cell.item)" icon color="#4aa832" v-on="on">
                        <v-icon>mdi-eye-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Más</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                        <v-icon>mdi-xml</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar XML</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar PDF</span>
                  </v-tooltip>
                  
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
            </wj-flex-grid>


          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';

export default {
  name: "ReporteDetalleNomina",
  components: {
    DateRangePicker
  },
  data: () => ({

    selectedDatesIn: [],
    selectedDates: [],
    showCantidades: false,
    total_sueldos: 0,
    total_gravado: 0,
    total_exento: 0,
    total_otras_deducciones: 0,
    total_impuestos_retenidos: 0,


    selectedRows: [],
    showDialogEliminarReport: false,
    showDialogReports: false,
    showDialogCrear: false,
    errorMessageIn: "",
    okMessageIn: "",
    alert_errorIn: false,
    alert_okIn: false,
    itemsGrandes: [],
    descripcionIn: "",
    itemDesc: "",

    showDialog: false,
    goPage: 1,
    folioDoc: "",
    modal: false,




    search: "",
    offest: 0,
    limit: 50,
    pageSize: "50",
    noPagina: 1,
    totalPaginas: 1,
    doctosPagina: 0,
    totalDocumentos: 0,
    paginations: ["25", "50", "100", "150", "200", "500"],
    items: [],
    landscape: false,
    title: "",
    csvData: [],
    csvDataT: [],
    currentOrder: "DESC",
    currentOrderColumn: "nfecha_emision",
    rfcClass: false,
    numero_empleadoClass: false,
    fecha_pagoClass: false,
    nfecha_emisionClass: true,
    itemSelected: {
      rfc: "",
      nombre: "",
      id: "",
      numero_empleado: "",
      curp: "",
      tipo_regimen: "",
      tipo_nomina: "",
      fecha_pago: "",
      fecha_inicio_pago: "",
      fecha_final_pago: "",
      numero_dias_pagados: "",
      periodicidad_pago: "",
      fecha_emision: "",
      total_sueldos: "",
      total_gravado: "",
      total_exento: "",
      total_otras_deducciones: "",
      total_impuestos_retenidos: "",
      total_percepciones: "",
      total_deducciones: "",
      total_otros_pagos: "",
      total_separacion_indemnizacion: "",
      total_jubilacion_pension_retiro: "",
      fecha_timbre: "",
    },
    rfcSearch: "",
    rfcSearchIn: "",
  }),
  methods: {
    cerrarCrearSolicitud() {
            this.descripcionIn = "";
            this.selectedDatesIn = [];
            this.$refs.dateRange.limpiarFechas();
            this.rfcSearchIn = "";
            this.showDialogCrear = false;
        },
    initGrid: function (grid) {

      for (let i = 1; i < grid.columns.length - 1; i++) {
        grid.autoSizeColumn(i);
      }

      
      grid.columns[3].width = 150;
      grid.columns[4].width = 150;
      grid.columns[5].width = 150;
      grid.columns[6].width = 150;
      grid.columns[7].width = 250;
      grid.columns[8].width = 250;



      grid.columns[grid.columns.length - 1].width = 200;

      
    },
    retrieveXML(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: "E",
        uuid: item.folio_fiscal == null ? item.id : item.folio_fiscal,
        issuedDate: item.fecha_emision,
      };
      service
        .api()
        .RetrieveXML(jsonData)
        .then((response) => {
          if (response.result) {
            let urlXML = response.payload;
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              window.open(urlXML, "_blank");
            } else {
              helpers.openNewBackgroundTab(urlXML);
            }
            setTimeout(() => {
              this.$store.commit("setOverlay", false);
            }, 1000);
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    retrievePDF(item) {
      this.$store.commit("setOverlay", true);
      let par = "";
      if (item.tipo === "R")
        par =
          item.tipo +
          "|" +
          helpers.ConvertToEmpty(item.pac).trim() +
          "|" +
          item.rfc;
      else
        par = item.tipo + "|" + helpers.ConvertToEmpty(item.pac).trim() + "|";
      let parameters =
        par +
        "|" +
        (item.folio_fiscal == null ? item.id : item.folio_fiscal) +
        "|" +
        item.fecha_emision +
        "|N";
      let urlPDF = "";
      this.$store.commit("setAlertError", false);
      this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);
        this.$store.commit("setOverlay", false);
      }, 2000);
    },
    verMasDatos(item) {
      this.itemSelected = item;
      this.showDialog = true;
    },

    exportExcel() {
      var headers = {
        RFCR: "RFC Emisor",
        Rfc: "RFC Receptor",
        Nombre: "Razón Social Receptor",
        UUID: "Folio Fiscal",
        NumeroEmpleado: "Número Empleado",
        Curp: "CURP",

        FechaInicio: "Fecha Inicio Relación Laboral",
        Antiguedad: "Antigüedad",
        TipoContrato: "Tipo Contrato",
        TipoJornada: "Tipo Jornada",
        Departamento: "Departamento",

        RegistroPatronal:"Registro Patronal",
        NoSeguridadSocial:"Número de Seguridad Social",
        Sindicalizado:"Sindicalizado",
        Puesto:"Puesto",
        RiesgoPuesto:"Riesgo Puesto",
        SalarioBase:"Salario Base",
        SalarioDiario:"Salario Diario Integrado",



        CTipoRegimen: "Código Regimen",
        NTipoRegimen: "Regimen",
        TipoNomina: "Tipo Nomina",
        FechaPago: "Fecha Pago",
        FechaInicioPago: "Fecha Inicio Pago",
        FechaFinalPago: "Fecha Final Pago",
        NumDias: "Número Dias Pagados",
        CPeriodicidad: "Código Periodicidad de Pago",
        NPeriodicidad: "Periodicidad de Pago",
        FechaEmision: "FechaEmisión",
        TotalSueldos: "Total Sueldos",
        TotalGravado: "Total Gravado",
        TotalExento: "Total Exento",
        TotalOtrasDeducciones: "Total Otras Deducciones",
        TotalImpuestosRetenidos: "Total Impuestos Retenidos",
        TotalPercepciones: "Total Percepciones",
        TotalDeducciones: "Total Deducciones",
        TotalOtrosPagos: "Total Otros Pagos",
        TotalSeparacion: "Total Separacion Indemnizacion",
        TotalJubilacion: "Total Jubilacion",
        FechaTimbre: "Fecha Timbre",
      };
      var itemsFormatted = [];

      this.csvData.forEach((item) => {
        let par = item.particion.split("-");
        itemsFormatted.push({
          RFCR: helpers.ConvertToEmpty(par[0]),
          Rfc: helpers.ConvertToEmpty(item.rfc),
          Nombre: helpers.ConvertToEmpty(item.nombre),
          UUID: helpers.ConvertToEmpty(
            item.folio_fiscal == null ? item.id : item.folio_fiscal
          ),
          NumeroEmpleado: helpers.ConvertToEmpty(item.numero_empleado),
          Curp: helpers.ConvertToEmpty(item.curp),



          FechaInicio:helpers.ConvertToEmpty(item.fecha_inicio_rel_laboral),
          Antiguedad:helpers.ConvertToEmpty(item.antiguedad),
          TipoContrato:helpers.ConvertToEmpty(item.tipo_contrato),
          TipoJornada:helpers.ConvertToEmpty(item.tipo_jornada),
          Departamento:helpers.ConvertToEmpty(item.departamento),

          RegistroPatronal:helpers.ConvertToEmpty(item.registro_patronal),
          NoSeguridadSocial:helpers.ConvertToEmpty(item.num_seguridad_social),
          Sindicalizado:helpers.ConvertToEmpty(item.sindicalizado),
          Puesto:helpers.ConvertToEmpty(item.puesto),
          RiesgoPuesto:helpers.ConvertToEmpty(item.riesgo_puesto),
          SalarioBase:helpers.ConvertToEmpty(item.salario_base_cotapor),
          SalarioDiario:helpers.ConvertToEmpty(item.salario_diario_integrado),


          CTipoRegimen: helpers.ConvertToEmpty(item.tipo_regimen),
          NTipoRegimen: helpers
            .RegimenNomina(helpers.ConvertToEmpty(item.tipo_regimen))
            .replace(/,/g, " "),
          TipoNomina: helpers
            .TipoNomina(helpers.ConvertToEmpty(item.tipo_nomina))
            .replace(/,/g, " "),
          FechaPago: helpers.ConvertToEmpty(item.fecha_pago),
          FechaInicioPago: helpers.ConvertToEmpty(item.fecha_inicio_pago),
          FechaFinalPago: helpers.ConvertToEmpty(item.fecha_final_pago),
          NumDias: helpers.ConvertToEmpty(item.numero_dias_pagados),
          CPeriodicidad: helpers.ConvertToEmpty(item.periodicidad_pago),
          NPeriodicidad: helpers
            .Periodicidad(helpers.ConvertToEmpty(item.periodicidad_pago))
            .replace(/,/g, " "),
          FechaEmision: helpers.ConvertToEmpty(item.fecha_emision),
          TotalSueldos: helpers.ConvertToEmpty(item.total_sueldos),
          TotalGravado: helpers.ConvertToEmpty(item.total_gravado),
          TotalExento: helpers.ConvertToEmpty(item.total_exento),
          TotalOtrasDeducciones: helpers.ConvertToEmpty(
            item.total_otras_deducciones
          ),
          TotalImpuestosRetenidos: helpers.ConvertToEmpty(
            item.total_impuestos_retenidos
          ),
          TotalPercepciones: helpers.ConvertToEmpty(item.total_percepciones),
          TotalDeducciones: helpers.ConvertToEmpty(item.total_deducciones),
          TotalOtrosPagos: helpers.ConvertToEmpty(item.total_otros_pagos),
          TotalSeparacion: helpers.ConvertToEmpty(
            item.total_separacion_indemnizacion
          ),
          TotalJubilacion: helpers.ConvertToEmpty(
            item.total_jubilacion_pension_retiro
          ),
          FechaTimbre: helpers.ConvertToEmpty(item.fecha_timbre),
        });
      });

      let datos = {
        "RFC Receptor": helpers.ConvertToNoAplica(this.rfcSearch),
        "Fecha Final": this.selectedDates[1],
        "Fecha Inicio": this.selectedDates[0],
        "Fecha de Solicitud": helpers.FechaActual(),
        "Reporte": "Listado Detalle Nomina"
      };

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Listado_Detalle_Nomina_" + helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFileV1(headers, itemsFormatted, fileTitle, datos);
    },
    start() {
      this.offest = 0;
      this.goPage = this.noPagina = 1;
      this.limit = this.pageSize;
      this.getReporteItems();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;
      this.getReporteItems();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalDocumentos % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItems();
    },
    end() {
      this.limit = this.pageSize;
      let res = this.totalDocumentos % this.pageSize;
      if (res !== 0) this.limit = res;

      this.goPage = this.noPagina = this.totalPaginas;
      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
      this.getReporteItems();
    },
    goToPage() {
      this.limit = this.pageSize;
      let actualPage = this.noPagina;
      this.noPagina = parseInt(this.goPage);

      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

      if (this.noPagina > 0 && this.noPagina <= noPaginas)
        this.getReporteItems();
      else this.noPagina = this.goPage = actualPage;
    },
    changePagination() {
      this.getReport();
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    handleDate() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.$refs.menu.save(this.date);
      this.getReporteItems();
    },
    getReport() {
      this.goPage = 1;
      this.offest = 0;
      this.noPagina = 1;
      this.limit = this.pageSize;
      this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.getTotalCount();
    },
    getReporteItems() {
      this.items = [];
      this.selectedRows = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        orderColumn: this.currentOrderColumn,
        order: this.currentOrder,
        offset: this.offest,
        limit: this.limit,
        rfc: this.rfcSearch,
      };
      service
        .api()
        .NominaDetalleLista(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = payload;


            this.doctosPagina = this.items.length.toString();
            this.getReportNominaCantidades();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getTotalCount() {
      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        orderColumn: this.currentOrderColumn,
        order: this.currentOrder,
        rfc: this.rfcSearch,
      };

      service
        .api()
        .NominaDetalleCount(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.totalDocumentos = payload;
            this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReporteNominaCSV() {
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        rfc: this.rfcSearch,
      };

      service
        .api()
        .NominaDetalleCSV(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.csvData = JSON.parse(response.payload);
            this.exportExcel();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido.");
          }
        });
    },
    esOrdenamiento(columna) {
      return this.currentOrderColumn === columna;
    },
    sortColumn(columnName) {
      if (this.currentOrderColumn === columnName) {
        if (this.currentOrder === "DESC") {
          this.currentOrder = "ASC";
        } else {
          this.currentOrder = "DESC";
        }
      }
      this.currentOrderColumn = columnName;
      this.getReporteItems();

      this.rfcClass = false;
      this.numero_empleadoClass = false;
      this.fecha_pagoClass = false;
      this.nfecha_emisionClass = false;

      switch (columnName) {
        case "rfc":
          this.rfcClass = true;
          break;
        case "numero_empleado":
          this.numero_empleadoClass = true;
          break;
        case "nfecha_emision":
          this.fechaEClass = true;
          break;
        case "fecha_pago":
          this.fecha_pagoClass = true;
          break;
      }
    },
    getTipoComprobante(tipoDoc) {
      let tipo = "";

      switch (tipoDoc) {
        case "I":
          tipo = "Ingreso";
          break;
        case "E":
          tipo = "Egreso";
          break;
        case "T":
          tipo = "Traslado";
          break;
        case "P":
          tipo = "Pago";
          break;
      }
      return tipo;
    },
    getValue(item) {
      if (helpers.IsNotNull(item)) {
        return item.substr(0, 10);
      }
      else {
        return "";
      }
    },

    requestItems() {
      this.showDialogCrear = false;

      var jsonData = {
        description: this.descripcionIn,
        dateInit: this.selectedDatesIn[0],
        dateEnd: this.selectedDatesIn[1],
        taxid: this.rfcSearchIn,
        token: this.$store.state.token,
        reportType: 'COMPLEMENTO'
      };
      this.showDialogReports = false;
      service
        .api()
        .RequestNominaReport(jsonData)
        .then((response) => {
          this.cerrarCrearSolicitud();
          if (response.result) {
            this.alert_okIn = true;
            this.okMessageIn = "Reporte Solicitado Correctamente";
            setTimeout(() => {
              this.alert_okIn = false;
              this.okMessageIn = "";
            }, 10000);

          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
          this.selectedDatesIn[0] = '';
          this.selectedDatesIn[1] = '';
          this.rfcSearchIn = '';
          this.descripcionIn = "";
          this.showDialogReports = true;
        });


    },
    ObtenerReportes() {
      this.showDialogReports = true;
      var jsonData = {
        token: this.$store.state.token,
        reportType: 'COMPLEMENTO'
      };

      service
        .api()
        .RetrieveNominaReports(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.itemsGrandes = JSON.parse(response.payload);
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;

            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    ConfirmarEliminarReporte(item) {
      this.eliminarId = item.id;
      this.itemDesc = item.description;
      this.showDialogEliminarReport = true;
    },
    EliminarReporte() {
      var jsonData = {
        id: this.eliminarId,
        token: this.$store.state.token,
        reportType: 'COMPLEMENTO'
      };
      this.showDialogEliminarReport = false;
      service
        .api()
        .DeleteNominaReport(jsonData)
        .then((response) => {
          if (response.result) {
            this.alert_okIn = true;
            this.okMessageIn = "Reporte Eliminado Correctamente";
            setTimeout(() => {
              this.alert_okIn = false;
              this.okMessageIn = "";
            }, 10000);
            if (this.itemsGrandes.length == 1)
              this.itemsGrandes = [];
            else
              this.ObtenerReportes();
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    DescargarReporte(itemId) {
      var jsonData = {
        id: itemId,
        token: this.$store.state.token,
        reportType: 'COMPLEMENTO'
      };

      service
        .api()
        .RetrieveNominaReport(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.retrieveReporte(response.payload);
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    retrieveReporte(ruta) {
      this.$store.commit("setOverlay", true);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(ruta, "_blank");
      } else {
        helpers.openNewBackgroundTab(ruta);
      }
      setTimeout(() => {
        this.$store.commit("setOverlay", false);
      }, 1000);
    },




    getReportNominaCantidades() {

      this.total_sueldos = 0;
      this.total_gravado = 0;
      this.total_exento = 0;
      this.total_otras_deducciones = 0;
      this.total_impuestos_retenidos = 0;


      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        rfc: this.rfcSearch,
        dateTs: "",
        dateTs1: "",
        tags: "",
        note: ""
      };

      service
        .api()
        .NominaDetalleMontos(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.showCantidades = true;
            let data = JSON.parse(response.payload);
            this.total_sueldos = data.total_sueldos;
            this.total_gravado = data.total_gravado;
            this.total_exento = data.total_exento;
            this.total_otras_deducciones = data.total_otras_deducciones;
            this.total_impuestos_retenidos = data.total_impuestos_retenidos;
            this.showCantidades = true;

          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
  },
  computed: {

    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    showSearchButton() {
      return this.selectedDates.length > 1;
    },
    fechaInicial() {
      return helpers.getFechaLimite(false);
    },
    showCreateButton() {
      return this.selectedDatesIn.length > 1 && this.descripcionIn.length > 0;
    },
  },
};
</script>

<style scoped>
.altura {
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoGuia {
  width: 50px;
}


.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}

.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}

/* style frozen cells */

.wj-flexgrid {
  max-height: 600px;
  margin: 10px 0;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
  background: rgb(255, 255, 125);
}
</style>
