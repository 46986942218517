<template>
    <v-container fluid class="my-10">
        <v-row>

            <v-dialog v-model="showDialogCrear" persistent max-width="800px">
                <v-card dense>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="cerrarCrearSolicitud">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Crear Reporte sin límite de Documentos</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" justify="center">
                                <date-range-picker ref="dateRange" v-model="selectedDatesIn"></date-range-picker>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="rfcSearch" counter="0" label="RFC" class="my-1" maxlength="13">
                                </v-text-field>
                            </v-col>
                            <v-col class="d-flex" cols="12" md="6">
                                <v-select :items="itemsOrigen" v-model="contexto" label="Contexto" outlined>
                                </v-select>
                            </v-col>
                            <v-col md="8" justify="center">
                                <v-text-field v-model="descripcion" counter="0" label="Descripción*" class="my-1"
                                    maxlength="100">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider class="mt-12"></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="requestItems" text large :disabled="!showCreateButton">Solicitar
                            Reporte
                        </v-btn>
                        <v-btn color="danger" text @click="cerrarCrearSolicitud">Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
                <v-card dense>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="showDialogEliminarReport = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Confirmación Eliminar Reporte:
                            {{ this.itemDesc }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <br />
                        <h3>Este reporte sera eliminado permanentemente</h3>
                        <h3>¿Está seguro de continuar con esta acción?</h3>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
                        <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-col cols="12">
                <h3>Análisis de Comprobantes de Pago vs CFDI por Fecha de Pago


                </h3>
            </v-col>
            <v-col cols="12">
                <v-divider></v-divider>
            </v-col>
            <v-col cols="11">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Reportes Solicitados</v-toolbar-title>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="ObtenerReportes" icon v-on="on">
                                    <v-icon>mdi-cached</v-icon>
                                </v-btn>
                            </template>
                            <span>Refrescar Tabla</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                        <v-btn @click="showDialogCrear = true" large color="second">
                            <v-icon>mdi-plus</v-icon>Solicitar Reporte
                        </v-btn>
                    </v-toolbar>

                    <v-row>
                        <v-col cols="12">
                            <v-simple-table height="600" fixed-header dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>Descripción</th>
                                            <th>Fecha Solicitud</th>
                                            <th>Contexto</th>
                                            <th>Fecha Inicial</th>
                                            <th>Fecha Final</th>
                                            <th>RFC</th>
                                            <th>Mensaje</th>
                                            <th>Disponible</th>
                                            <th>Resultado</th>

                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in itemsGrandes" :key="item.id" class="altura">
                                            <td>
                                                {{ item.description }}
                                            </td>
                                            <td>
                                                {{ item.requestDate }}
                                            </td>

                                            <td>
                                                {{
                                                    JSON.parse(item.commands).context == "E" ? "Emitido" : "Recibido"
                                                }}
                                            </td>
                                            <td style="white-space: nowrap">
                                                {{ JSON.parse(item.commands).dateInit }}
                                            </td>
                                            <td style="white-space: nowrap">
                                                {{ JSON.parse(item.commands).dateEnd }}
                                            </td>
                                            <td>
                                                {{ JSON.parse(item.commands).rfc }}
                                            </td>
                                            <td>
                                                {{ item.message }}
                                            </td>
                                            <td>
                                                {{ item.ready ? "Si" : "No" }}
                                            </td>
                                            <td>
                                                {{
                                                    item.ready
                                                        ? item.result
                                                            ? "Exitoso"
                                                            : "Fracasado"
                                                        : "Procesando"
                                                }}
                                            </td>
                                            <td class="pa-0 md-0">
                                                <v-tooltip left v-if="item.ready && item.result">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn @click="DescargarReporte(item.id)" icon color="#4aa832"
                                                            v-on="on">
                                                            <v-icon>mdi-download</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Descargar Reporte</span>
                                                </v-tooltip>
                                            </td>
                                            <td class="pa-0 md-0">
                                                <v-tooltip left v-if="item.ready">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn @click="ConfirmarEliminarReporte(item)" icon color="red"
                                                            v-on="on">
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Eliminar Reporte</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import { JSONToCSV } from "../../scripts/helpers.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';

export default {
    name: "PaymentVsCfdiByDate",
    components: {
        DateRangePicker
    },

    data: () => ({
        selectedDatesIn: [],
        showDialogEliminarReport: false,
        showDialogCrear: false,
        itemsGrandes: [],
        itemDesc: "",
        modal: false,




        search: "",
        items: [],
        landscape: false,
        title: "",
        rfcSearch: "",
        itemsYears: [],
        periodoModel: new Date().getFullYear(),
        nombre: "",
        rfcSearch: "",
        descripcion: "",
        itemDesc: "",
        contexto: "Emitidos"
    }),
    created() {
        this.fillItems();
        this.ObtenerReportes();
    },

    methods: {

        fillItems() {
            var i;
            for (i = this.periodoModel - 5; i <= this.periodoModel; i++)
                this.itemsYears.push(i);
        },

        requestItems() {
            this.showDialogCrear = false;
            var jsonData = {
                reportType: 'REPORT-PAGOS-CFDI-PAYMENT-DATE',
                name: "",
                description: this.descripcion,
                commands: JSON.stringify({ dateInit: this.selectedDatesIn[0], dateEnd: this.selectedDatesIn[1], context: this.contexto.substr(0, 1), rfc: this.rfcSearch }),
                token: this.$store.state.token,
            };
            service
                .api()
                .RequestOnDemandReport(jsonData)
                .then((response) => {
                    this.cerrarCrearSolicitud();
                    if (response.result) {

                        this.$store.commit("setAlertError", false);
                        this.$store.commit("setAlertOk", true);
                        this.$store.commit("setAlertMessage", "Reporte Solicitado Correctamente");

                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                    this.nombre = "";
                    this.descripcion = "";
                    this.selectedDatesIn[0] = "";
                    this.selectedDatesIn[1] = "";
                    this.contexto = "Emitidos";
                    this.rfcSearch = "";
                }
               
            );


        },
        ObtenerReportes() {
            var jsonData = {
                token: this.$store.state.token,
                reportType: 'REPORT-PAGOS-CFDI-PAYMENT-DATE'
            };

            service
                .api()
                .RetrieveOnDemandReports(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.itemsGrandes = JSON.parse(response.payload);

                        //this.itemsGrandes.commands = JSON.parse(this.itemsGrandes.commands);

                    } else {
                        this.itemsGrandes = [];

                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        ConfirmarEliminarReporte(item) {
            this.eliminarId = item.id;
            this.itemDesc = item.description;
            this.showDialogEliminarReport = true;
        },
        EliminarReporte() {
            var jsonData = {
                id: this.eliminarId,
                token: this.$store.state.token,
                reportType: 'REPORT-PAGOS-CFDI-PAYMENT-DATE'
            };
            this.showDialogEliminarReport = false;
            service
                .api()
                .DeleteOnDemandReport(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setAlertError", false);
                        this.$store.commit("setAlertOk", true);
                        this.$store.commit("setAlertMessage", "Reporte Eliminado Correctamente");
                        this.ObtenerReportes();
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        DescargarReporte(itemId) {
            var jsonData = {
                id: itemId,
                token: this.$store.state.token
            };

            service
                .api()
                .RetrieveOnDemandReport(jsonData)
                .then((response) => {
                    if (response.result) {
                        this.$store.commit("setOverlay", false);
                        this.retrieveReporte(response.payload);
                    } else {
                        this.$store.commit("setAlertOk", false);
                        this.$store.commit("setAlertError", true);
                        this.$store.commit("setAlertMessage", response.errorMessage);
                    }
                });
        },
        retrieveReporte(ruta) {
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
                window.open(ruta, "_blank");
            } else {
                helpers.openNewBackgroundTab(ruta);
            }
            setTimeout(() => {
                this.$store.commit("setOverlay", false);
            }, 1000);
        },
        getTipoDoc() {
            let firstLetter = this.tipoDoc.substr(0, 1);
            return firstLetter === "S" ? "" : firstLetter;
        },
        parseDocType(tipoDoc) {

            return helpers.parseDoctype(tipoDoc);

        },
        cerrarCrearSolicitud() {
            this.nombre = "";
            this.descripcion = "";
            this.selectedDatesIn = [];
            this.$refs.dateRange.limpiarFechas();
            this.contexto = "Emitidos";
            this.rfcSearch = "";
            this.showDialogCrear = false;
        },



    },
    computed: {
        showCreatedButton() {
            return this.rfcSearch.length >= 12 && this.rfcSearch.length <= 13;
        },
        showSolicitarReporte() {
            return this.descripcionIn.length > 3;
        },
        fechaInicial() {
            return helpers.getFechaLimite(false);
        },
        showCreateButton() {
            return this.selectedDatesIn.length > 1 && this.descripcion.length > 0;
        },
        allowIssued() {
            return this.$store.state.allowIssued === true;
        },
        allowReceived() {
            return this.$store.state.allowReceived === true;
        },
        itemsOrigen() {
            let elements = [];
            if (this.allowIssued)
                elements.push("Emitidos");
            if (this.allowReceived)
                elements.push("Recibidos");

            if (!this.allowIssued) {
                this.contexto = "Recibidos";
            }

            return elements;
        },
    },
};
</script>
<style scoped>
.altura {
    height: 40px;
}

.negro {
    font-weight: bold;
}

.ancho {
    width: 35px;
}

.anchoP {
    width: 100px;
}

.normal {
    font-weight: regular;
}

.tamPagina {
    font-weight: bold;
    font-size: 20px;
}
</style>