<template>
  <div class="py-10 mt-5">
    <v-container fluid class="px-10">
      <v-row justify="center">
        <dialogo ref="dialog" :itemFind="itemFind"></dialogo>
        <expediente ref="exped" :itemFind="itemFindEx"></expediente>

        <v-dialog v-model="showDialogEliminarReport" persistent max-width="900px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogEliminarReport = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Confirmación Eliminar Reporte:
                {{ this.itemDesc }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <br />
              <h3>Este reporte sera eliminado permanentemente</h3>
              <h3>¿Está seguro de continuar con esta acción?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="EliminarReporte">Confirmar</v-btn>
              <v-btn color="danger" text @click="showDialogEliminarReport = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogReports" persistent>
          <v-col cols="12">
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="showDialogReports = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Reportes Solicitados</v-toolbar-title>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="ObtenerReportes" icon v-on="on">
                      <v-icon>mdi-cached</v-icon>
                    </v-btn>
                  </template>
                  <span>Refrescar Tabla</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-btn @click="showDialogCrear = true" large color="second">
                  <v-icon>mdi-plus</v-icon>Solicitar Reporte
                </v-btn>
              </v-toolbar>
              <v-row>
                <v-col cols="12">
                  <v-alert :value="alert_errorIn" type="error" transition="scale-transition">{{ errorMessageIn }}
                  </v-alert>
                  <v-alert :value="alert_okIn" type="success" transition="scale-transition">{{ okMessageIn }}</v-alert>
                </v-col>
                <v-col cols="12">
                  <v-simple-table height="600" fixed-header dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Descripción</th>
                          <th>Fecha Solicitud</th>
                          <th>Contexto</th>
                          <th>Tipo Comprobante</th>
                          <th>Fecha Inicial</th>
                          <th>Fecha Final</th>
                          <th>RFC</th>
                          <th>Estado de Pago</th>
                          <th>Método de Pago</th>
                          <th>Mensaje</th>
                          <th>Disponible</th>
                          <th>Resultado</th>

                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in itemsGrandes" :key="item.id" class="altura">
                          <td>
                            {{ item.description }}
                          </td>
                          <td>
                            {{ item.requestDate }}
                          </td>
                          <td>
                            {{
                              item.commands.context == "E"
                              ? "Emitido"
                              : "Recibido"
                            }}
                          </td>
                          <td>
                            {{ parseDocType(item.commands.docType) }}
                          </td>

                          <td>
                            {{ item.commands.dateInit }}
                          </td>
                          <td>
                            {{ item.commands.dateEnd }}
                          </td>
                          <td>
                            {{ item.commands.taxid }}
                          </td>
                          <td>
                            {{ item.commands.paymentStatus }}
                          </td>
                          <td>
                            {{ item.commands.paymentMethod }}
                          </td>
                          <td>
                            {{ item.message }}
                          </td>
                          <td>
                            {{ item.ready ? "Si" : "No" }}
                          </td>
                          <td>
                            {{
                              item.ready
                              ? item.result
                                ? "Exitoso"
                                : "Fracasado"
                              : "Procesando"
                            }}
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left v-if="item.ready && item.result">
                              <template v-slot:activator="{ on }">
                                <v-btn @click="DescargarReporte(item.id)" icon color="#4aa832" v-on="on">
                                  <v-icon>mdi-download</v-icon>
                                </v-btn>
                              </template>
                              <span>Descargar Reporte</span>
                            </v-tooltip>
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left v-if="item.ready">
                              <template v-slot:activator="{ on }">
                                <v-btn @click="ConfirmarEliminarReporte(item)" icon color="red" v-on="on">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Eliminar Reporte</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-dialog>
        <v-dialog v-model="showDialogCrear" persistent max-width="800px">
          <v-card dense>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="cerrarCrearSolicitud">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Crear Reporte sin límite de Documentos</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" justify="center">
                  <date-range-picker ref="dateRange" v-model="selectedDatesIn"></date-range-picker>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="rfcSearchIn" counter="0" :label="labelRfc" class="my-1" maxlength="13">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="guidIncomeSearchIn" counter="0" label="Folio Fiscal" class="my-1"
                    maxlength="36"></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" md="4">
                  <v-select :items="itemsSelect" v-model="tipoPagoIn" label="Estado de Pago" outlined
                    :disabled="dateTs.length > 0"></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" md="4">
                  <v-select :items="itemsOrigen" v-model="contextoIn" label="Contexto" outlined
                    :disabled="dateTs.length > 0"></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select :items="metodosPago" v-model="metodoPagoIn" label="Método de Pago" outlined item-text="opcion"
                    item-value="valor" :disabled="dateTs.length > 0"></v-select>
                </v-col>
                <!-- <v-col class="d-flex" cols="12" sm="4">
                  <v-select :items="itemsSelectComprobante" v-model="tipoDocIn" label="Tipo de Comprobante" outlined>
                  </v-select>
                </v-col> -->
                <v-col md="6" justify="center">
                  <v-text-field v-model="descripcionIn" counter="0" label="Descripción*" class="my-1" maxlength="100">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="requestItems" text large :disabled="!showCreateButton">Solicitar Reporte
              </v-btn>
              <v-btn color="danger" text @click="cerrarCrearSolicitud">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showDialogRelacionados" persistent max-width="1500px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialogRelacionados = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Documentos Relacionados del documento :
                {{ itemSelectedDocs.serie }} -
                {{ itemSelectedDocs.folio }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-if="esRecibido">RFC Emisor</th>
                      <th v-if="esRecibido">Razon Social Emisor</th>
                      <th v-if="esEmitido">RFC Receptor</th>
                      <th v-if="esEmitido">Razon Social Receptor</th>
                      <th>Serie</th>
                      <th>Folio</th>
                      <th>Tipo Comprobante</th>
                      <th>Moneda</th>
                      <th>Total</th>
                      <th style="white-space: nowrap">Fecha Emisión</th>
                      <!-- <th>Fecha Cancelación</th> -->
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in itemsRel" :key="item.id" class="altura">
                      <td v-if="esRecibido">{{ item.emisor.rfc }}</td>
                      <td v-if="esRecibido">{{ item.emisor.nombre }}</td>
                      <td v-if="esEmitido">{{ item.receptor.rfc }}</td>
                      <td v-if="esEmitido">{{ item.receptor.nombre }}</td>
                      <td>{{ item.serie }}</td>
                      <td>{{ item.folio }}</td>
                      <td>
                        {{ getTipoComprobante(item.tipo_comprobante) }}
                      </td>
                      <td>{{ item.moneda }}</td>
                      <td>{{ currencyFormat(item.total) }}</td>
                      <td>{{ item.fecha_emision.substr(0, 10) }}</td>
                      <!-- <td>
                                            {{ item.fecha_cancelacion }}
                                        </td> -->
                      <td class="pa-0 md-0">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn @click="retrieveXML(item)" icon color="blue" v-on="on">
                              <v-icon>mdi-xml</v-icon>
                            </v-btn>
                          </template>
                          <span>Descargar XML</span>
                        </v-tooltip>
                      </td>
                      <td class="pa-0 md-0">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn @click="retrievePDF(item)" icon color="red" v-on="on">
                              <v-icon>mdi-file-pdf-box</v-icon>
                            </v-btn>
                          </template>
                          <span>Descargar PDF</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="showDialogRelacionados = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showDialog" persistent width="1700px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="showDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Pagos y sus características como documento relacionado:
                {{ serieSelected }} - {{ folioSelected }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <br />
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th colspan="2" style="background-color: #90caf9">
                        Datos del CFDI de Pago
                      </th>
                      <th colspan="4" style="background-color: #bbdefb">
                        Datos del Pago
                      </th>
                      <th colspan="9" style="background-color: #e3f2fd">
                        Datos del Documento Relacionado
                      </th>
                    </tr>
                    <tr>
                      <th style="white-space: nowrap">Folio Fiscal</th>
                      <th style="white-space: nowrap">Fecha Emisión</th>
                      <th style="white-space: nowrap">Fecha Pago</th>
                      <th>Moneda</th>
                      <th align="right" style="white-space: nowrap">
                        Tipo Cambio
                      </th>
                      <th>Monto</th>
                      <th>Serie</th>
                      <th>Folio</th>
                      <th>Método Pago</th>
                      <th style="white-space: nowrap">Moneda</th>
                      <th align="right" style="white-space: nowrap">
                        Tipo Cambio
                      </th>
                      <th align="right" style="white-space: nowrap">
                        Número Parcialidad
                      </th>
                      <th align="right" style="white-space: nowrap">
                        Importe Pagado
                      </th>
                      <th align="right" style="white-space: nowrap">
                        Saldo Anterior
                      </th>
                      <th align="right" style="white-space: nowrap">
                        Saldo Insoluto
                      </th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="altura" v-for="itemS in itemSelected" :key="itemS.cfdi_pago_folio_fiscal">
                      <td>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">{{
                              itemS.cfdi_pago_folio_fiscal.substring(0, 15) +
                              "..."
                            }}</span>
                          </template>
                          <span>{{ itemS.cfdi_pago_folio_fiscal }}</span>
                        </v-tooltip>
                      </td>
                      <td>
                        {{ itemS.cfdi_pago_fecha_emision.substring(0, 10) }}
                      </td>
                      <td>{{ itemS.pago_fecha_pago.substring(0, 10) }}</td>
                      <td>{{ itemS.pago_moneda }}</td>
                      <td align="right">
                        {{ currencyFormat6(itemS.pago_tipo_cambio) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemS.pago_monto) }}
                      </td>
                      <td>{{ itemS.rel_serie }}</td>
                      <td>{{ itemS.rel_folio }}</td>
                      <td>{{ itemS.rel_metodo_pago }}</td>
                      <td>{{ itemS.rel_moneda }}</td>
                      <td align="right">{{ itemS.rel_tipo_cambio }}</td>
                      <td align="right">{{ itemS.rel_num_parcialidad }}</td>
                      <td align="right">
                        {{ currencyFormat(itemS.rel_imp_pagado) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemS.rel_imp_saldo_anterior) }}
                      </td>
                      <td align="right">
                        {{ currencyFormat(itemS.rel_imp_saldo_insoluto) }}
                      </td>
                      <td class="pa-0 md-0">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn @click="retrievePagoXML(itemS)" icon color="blue" v-on="on">
                              <v-icon>mdi-xml</v-icon>
                            </v-btn>
                          </template>
                          <span>Descargar XML</span>
                        </v-tooltip>
                      </td>
                      <td class="pa-0 md-0">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn @click="retrievePagoPDF(itemS)" icon color="red" v-on="on">
                              <v-icon>mdi-file-pdf-box</v-icon>
                            </v-btn>
                          </template>
                          <span>Descargar PDF</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="showDialog = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>

        <v-col cols="12">
          <h3>Análisis de CFDI vs Comprobantes de Pago
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-show="$store.state.token.length" v-on="on"
                  href="https://soporte.mysuitemex.com/portal/es/kb/articles/analisis-de-cfdi-vs-comprobantes-de-pago"
                  target="_blank">
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>Manual</span>
            </v-tooltip>
          </h3>
        </v-col>
        <v-col>
          <v-card class="paddingCard">
            <v-row>
              <v-col cols="12" md="4">
                <date-range-picker v-model="selectedDates" :disabled="dateTs.length > 0"></date-range-picker>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-text-field v-model="rfcSearch" counter="0" :label="labelRfc" class="my-1" maxlength="13"
                  :disabled="dateTs.length > 0"></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-text-field v-model="guidIncomeSearch" counter="0" label="Folio Fiscal" class="my-1" maxlength="36">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="itemsSelect" v-model="tipoPago" label="Estado de Pago"
                  outlined :disabled="dateTs.length > 0"></v-select>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="itemsOrigen" v-model="contexto" label="Contexto" outlined
                  @change="items = []" :disabled="dateTs.length > 0"></v-select>
              </v-col>
              <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="metodosPago" v-model="metodoPago" label="Método de Pago"
                  outlined item-text="opcion" item-value="valor" :disabled="dateTs.length > 0"></v-select>
              </v-col>
              <!-- <v-col cols="12" md="2" class="px-2 mx-md-10">
                <v-select class="min-width-control-xl" :items="tiposDoc" v-model="tipoDoc" label="Tipo Comprobante"
                  outlined item-text="opcion" item-value="valor" :disabled="dateTs.length > 0"></v-select>
              </v-col> -->
              <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                <v-btn color="primary" white large v-on:click="getReport" :disabled="!showSearchButton">Mostrar Listado
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="2" class="px-2 mx-md-10">
                <v-tooltip top>
                  <template v-slot:activator="{ on }" class="d-none d-sm-flex">
                    <v-btn color="primary" white large v-on="on" v-on:click="ObtenerReportes">Reportes Bajo Demanda
                    </v-btn>
                  </template>
                  <span class="d-none d-sm-flex">Reportes Solicitados Grandes</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="items.length > 0">
        <v-col cols="12">
          <v-toolbar>
            <v-toolbar-title class="d-none d-sm-flex">{{ doctosPagina }} registros de
              {{ totalDocumentos }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-tooltip top class="d-none d-sm-flex">
              <template v-slot:activator="{ on }" class="d-none d-sm-flex">
                <v-icon class="d-none d-sm-flex" @click="getReporteConciliatorioCSV" v-on="on"
                  :disabled="totalDocumentos > 1000" color="second">mdi-file-export</v-icon>
              </template>
              <span class="d-none d-sm-flex">Exportar tabla a CSV. Hasta 1,000 registros</span>
            </v-tooltip>

            <v-toolbar-items class="pa-3">
              <v-select v-model="pageSize" v-on:change="changePagination" :items="paginations" menu-props="auto"
                label="Total de Registros" hide-details class="ma-3 anchoP"></v-select>
              <v-tooltip v-if="hasPrevious" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex mr-2" @click="previous" v-on="on" large color="second">
                    mdi-arrow-up-bold-circle-outline</v-icon>
                </template>
                <span>Página anterior</span>
              </v-tooltip>
              <v-icon v-else large class="mr-2" color="disable">mdi-arrow-up-bold-circle-outline</v-icon>

              <v-tooltip v-if="hasNext" top class="d-none d-sm-flex">
                <template v-slot:activator="{ on }">
                  <v-icon class="d-none d-sm-flex ml-2" @click="next" v-on="on" large color="second">
                    mdi-arrow-down-bold-circle-outline</v-icon>
                </template>
                <span>Página siguiente</span>
              </v-tooltip>
              <v-icon v-else large class="ml-2" color="disable">mdi-arrow-down-bold-circle-outline</v-icon>

              <v-btn text>
                <v-text-field type="number" min="1" :max="totalPaginas" v-model="goPage" class="mr-1 mb-0 anchoGuia"
                  @keyup.enter.native="goToPage"></v-text-field>
                <span class="tamPagina">/{{ totalPaginas }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card>

            <wj-flex-grid :itemsSource="items" :autoGenerateColumns="false" :allowAddNew="false" :allowDelete="false"
              :allowPinning="'SingleColumn'" :newRowAtTop="false" :showMarquee="true" :validateEdits="false"
              :frozenColumns="1" :initialized="initGrid" :allowResizing="'None'">


              <wj-flex-grid-column :header="'Estado'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ ObtenerEstado(cell.item.estado) }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
              <wj-flex-grid-column :header="'Serie'" :binding="'serie'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Folio'" :binding="'folio'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Folio Fiscal'" :binding="'id'" width="4*" :isReadOnly="true" />

              <wj-flex-grid-column v-if="esEmitido" :header="'RFC Receptor'" :binding="'rfc'" width="2*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-else :header="'RFC Emisor'" :binding="'rfc'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column v-if="esEmitido" :header="'Razón Social'" :binding="'nombre'" width="4*"
                :isReadOnly="true" />
              <wj-flex-grid-column v-else :header="'Razón Social'" :binding="'nombre'" width="4*" :isReadOnly="true" />



              <wj-flex-grid-column :header="'Fecha Emisión'" :binding="'fecha_emision'" width="2*" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Subtotal'" :binding="'subtotal'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total'" :binding="'total'" width="2*" format="c2" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Moneda'" :binding="'moneda'" :isReadOnly="true" />
              <wj-flex-grid-column :header="'Tipo Cambio'" :binding="'tipo_cambio'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Total MXN'" :binding="'totalmxn'" width="2*" format="c2"
                :isReadOnly="true" />

              <wj-flex-grid-column :header="'Tipo Comprobante'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <span>{{ getTipoComprobante(cell.item.tipo_comprobante) }}</span>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
              <wj-flex-grid-column :header="'Metodo de Pago'" :binding="'metodo_pago'" width="2*" :isReadOnly="true" />

              <wj-flex-grid-column :header="'Retención ISR'" :binding="'retencion_isr'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Retención IVA'" :binding="'retencion_iva'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Retención IEPS'" :binding="'retencion_ieps'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Traslado IVA'" :binding="'traslado_iva'" width="2*" format="c2"
                :isReadOnly="true" />
              <wj-flex-grid-column :header="'Traslado IEPS'" :binding="'traslado_ieps'" width="2*" format="c2"
                :isReadOnly="true" />

               <wj-flex-grid-column :header="'Relacionado en otros CFDIs'" width="4*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <div class="d-flex justify-center">
                    <v-btn v-if="cell.item.es_relacionado"
                      @click="ConsultarExpediente(cell.item)" icon color="primary">
                      <v-icon>mdi-file-tree</v-icon>
                    </v-btn>
                  </div>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
              
              <wj-flex-grid-column :header="'Importe Pagado MXN'" :binding="'importe_pagado_mxn'" width="2*" format="c2"
                :isReadOnly="true" />

              <wj-flex-grid-column :header="'Pendiente MXN'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <v-chip :color="getColor(cell.item.estado)" dark>{{
                    currencyFormat(calculatePendiente(cell.item))
                  }}</v-chip>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>



              <wj-flex-grid-column :header="' '" width="4*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell" width="*">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="verMasDatos(cell.item.id)" icon color="#4aa832" v-on="on">
                        <v-icon>mdi-eye-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Más</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrieveXML(cell.item)" icon color="blue" v-on="on">
                        <v-icon>mdi-xml</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar XML</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="retrievePDF(cell.item)" icon color="red" v-on="on">
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar PDF</span>
                  </v-tooltip>
                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>
              <wj-flex-grid-column :header="'Información de Pagos'" width="2*" :isReadOnly="true">
                <wj-flex-grid-cell-template cellType="Cell" v-slot="cell">

                  <table v-if="cell.item.pagos.length > 3">
                    <tbody>
                      <tr class="borde">
                        <th class="text-no-wrap caption">
                          <strong>Folio de Pago</strong>
                        </th>
                        <th class="text-no-wrap caption">
                          <strong>Fecha Emisión P&nbsp;</strong>
                        </th>
                        <th class="text-no-wrap caption">
                          <strong>Fecha de Pago </strong>
                        </th>
                        <th class="text-no-wrap caption">
                          <strong>Moneda P </strong>
                        </th>
                        <th class="text-no-wrap caption">
                          <strong>Tipo Cambio P </strong>
                        </th>
                        <th class="text-no-wrap caption">
                          <strong>Monto P </strong>
                        </th>
                        <th class="text-no-wrap caption">
                          <strong>Importe Pagado </strong>
                        </th>
                        <th class="text-no-wrap caption">
                          <strong>Saldo Anterior </strong>
                        </th>
                        <th class="text-no-wrap caption">
                          <strong>Saldo Insoluto </strong>
                        </th>
                        <th class="text-no-wrap caption">
                          <strong>Moneda DR </strong>
                        </th>
                        <th class="text-no-wrap caption">
                          <strong>Tipo Cambio DR </strong>
                        </th>
                        <th class="text-no-wrap caption"> <strong>Diferencia Cambiaria</strong></th>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <template>
                        <tr class="altura caption" :key="index" v-for="(pago, index) in JSON.parse(cell.item.pagos)"
                          :style="{
                            backgroundColor: pago.cancelado
                              ? '#FBE9E7'
                              : 'transparent',
                          }">
                          <td class="text-no-wrap" style="width: 30%">
                            {{ pago.id }}
                          </td>
                          <td class="text-no-wrap" style="width: 20%" align="center">
                            {{ pago.fecha_emision.substring(0, 10) }}
                          </td>
                          <td class="text-no-wrap" style="width: 20%" align="center">
                            {{ pago.fecha_pago.substring(0, 10) }}
                          </td>
                          <td class="text-no-wrap" style="width: 10%" align="center">
                            {{ pago.moneda_p }}
                          </td>
                          <td class="text-no-wrap" style="width: 10%" align="right">
                            {{ pago.tipo_cambio_p }}
                          </td>
                          <td class="text-no-wrap" style="width: 20%" align="right">
                            {{ currencyFormat(pago.monto) }}
                          </td>
                          <td class="text-no-wrap" style="width: 20%" align="right">
                            {{ currencyFormat(pago.imp_pagado) }}
                          </td>
                          <td class="text-no-wrap" style="width: 20%" align="right">
                            {{ currencyFormat(pago.imp_saldo_anterior) }}
                          </td>
                          <td class="text-no-wrap" style="width: 20%" align="right">
                            {{ currencyFormat(pago.imp_saldo_insoluto) }}
                          </td>
                          <td class="text-no-wrap" style="width: 10%" align="center">
                            {{ pago.moneda_dr }}
                          </td>
                          <td class="text-no-wrap" style="width: 5%" align="right">
                            {{ pago.tipo_cambio_dr }}
                          </td>
                          <td class="text-no-wrap" style="width: 5%" align="right">
                            {{ currencyFormat(pago.diferencia_cambiaria) }}
                          </td>
                          <td class="pa-0 md-0">

                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn @click="verMasDatos(pago.id)" icon color="#4aa832" v-on="on">
                                  <v-icon>mdi-eye-plus-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Ver Más</span>
                            </v-tooltip>
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn @click="retrieveXML(pago)" icon color="blue" v-on="on">
                                  <v-icon>mdi-xml</v-icon>
                                </v-btn>
                              </template>
                              <span>Descargar XML Pago</span>
                            </v-tooltip>
                          </td>
                          <td class="pa-0 md-0">
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn @click="retrievePDFPago(item, pago)" icon color="red" v-on="on">
                                  <v-icon>mdi-file-pdf-box</v-icon>
                                </v-btn>
                              </template>
                              <span>Descargar PDF Pago</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>



                </wj-flex-grid-cell-template>
              </wj-flex-grid-column>

            </wj-flex-grid>



          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.vue2.grid";
import * as wjcCore from "@grapecity/wijmo";
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

import helpers from "../../scripts/helpers.js";
import service from "../../scripts/services.js";
import DateRangePicker from '../Componentes/DateRangePicker.vue';
import Dialogo from '../Componentes/DialogoBuscar.vue';
import Expediente from '../Componentes/Expediente.vue';

export default {
  name: "Income",
  components: {
    DateRangePicker,
    Dialogo,
    Expediente
  },
  data: () => ({
    selectedDates: [],
    selectedDatesIn: [],
    selectedRows: [],
    descripcionIn: "",
    showDialogEliminarReport: false,
    itemDesc: "",
    conPago: false,
    eliminarId: "",
    showDialogCrear: false,
    contexto: "Emitidos",
    contextoIn: "Emitidos",
    metodoPago: "",
    metodoPagoIn: "",
    showDialogReports: false,
    metodosPago: [
      {
        opcion: "Todos",
        valor: "",
      },
      {
        opcion: "Solo PPD",
        valor: "1",
      },
      {
        opcion: "Solo PUE",
        valor: "2",
      },
      {
        opcion: "Solo PUE sin Pagos",
        valor: "3",
      },
      {
        opcion: "Solo PUE con Pagos",
        valor: "4",
      },
    ],
    tipoDoc: "",
    tiposDoc: [
      {
        opcion: "Todos",
        valor: "",
      },
      {
        opcion: "Ingreso",
        valor: "I",
      },
      {
        opcion: "Egreso",
        valor: "E",
      }
    ],
    goPage: 1,
    tipoPago: "Seleccionar Todos",
    tipoPagoIn: "Seleccionar Todos",
    itemsSelect: ["Seleccionar Todos", "Pagado", "No Pagado", "Sobre Pagado"],
    showDialog: false,
    folioDoc: "",
    modal: false,
    itemFind: {
      id: "",
      emisor_rfc: "",
      emisor_nombre: "",
      regimen: "",
      receptor_nombre: "",
      residencia: "",
      receptor_rfc: "",
      usocfdi: "",
      fecha_cancelacion: "",
      fecha_carga: "",
      fecha_emision: "",
      fecha_timbre: "",
      folio: "",
      forma_pago: "",
      descuento: 0,
      lugar_expedicion: "",
      metodo_pago: "",
      moneda: "",
      serie: "",
      subtotal: 0,
      tipo: "",
      tipo_cambio: 1,
      tipo_comprobante: "",
      total: 0,
      total_retenciones: 0,
      total_traslados: 0,
      retencion_isr: 0,
      retencion_iva: 0,
      retencion_ieps: 0,
      traslado_iva: 0,
      traslado_ieps: 0,
    },
    itemFindEx: {
      id: "",
      emisor_rfc: "",
      emisor_nombre: "",
      regimen: "",
      receptor_nombre: "",
      residencia: "",
      receptor_rfc: "",
      usocfdi: "",
      fecha_cancelacion: "",
      fecha_carga: "",
      fecha_emision: "",
      fecha_timbre: "",
      folio: "",
      forma_pago: "",
      descuento: 0,
      lugar_expedicion: "",
      metodo_pago: "",
      moneda: "",
      serie: "",
      subtotal: 0,
      tipo: "",
      tipo_cambio: 1,
      tipo_comprobante: "",
      total: 0,
      total_retenciones: 0,
      total_traslados: 0,
      retencion_isr: 0,
      retencion_iva: 0,
      retencion_ieps: 0,
      traslado_iva: 0,
      traslado_ieps: 0,
    },


    errorMessageIn: "",
    okMessageIn: "",
    alert_errorIn: false,
    alert_okIn: false,
    search: "",
    offest: 0,
    limit: 50,
    pageSize: "50",
    noPagina: 1,
    totalPaginas: 1,
    doctosPagina: 0,
    totalDocumentos: 0,
    paginations: ["25", "50", "100", "150", "200"],
    items: [],
    itemsGrandes: [],
    landscape: false,
    title: "",
    csvData: [],
    csvDataT: [],
    currentOrder: "DESC",
    currentOrderColumn: "nfecha_emision",
    rfcRClass: false,
    serieClass: false,
    fechaEClass: true,
    rfcEClass: false,
    folioClass: false,
    monedaClass: false,
    tipo_comprobanteClass: false,
    metodo_pagoClass: false,
    importe_pagado_mxnClass: false,
    itemSelectedDocs: {
      id: "",
      emisor_rfc: "",
      emisor_nombre: "",
      regimen: "",
      receptor_nombre: "",
      residencia: "",
      receptor_rfc: "",
      usocfdi: "",
      fecha_cancelacion: "",
      fecha_carga: "",
      fecha_emision: "",
      fecha_timbre: "",
      folio: "",
      forma_pago: "",
      descuento: 0,
      lugar_expedicion: "",
      metodo_pago: "",
      moneda: "",
      serie: "",
      subtotal: 0,
      tipo: "",
      tipo_cambio: 1,
      tipo_comprobante: "",
      total: 0,
      total_retenciones: 0,
      total_traslados: 0,
      retencion_isr: 0,
      retencion_iva: 0,
      retencion_ieps: 0,
      traslado_iva: 0,
      traslado_ieps: 0,
    },
    itemSelected: [
      {
        cfdi_pago_folio_fiscal: "",
        cfdi_pago_fecha_emision: "",
        pago_fecha_pago: "",
        pago_moneda: "",
        pago_tipo_cambio: 0,
        pago_monto: 0,
        rel_serie: "",
        rel_folio: "",
        rel_metodo_pago: "",
        rel_moneda: "",
        rel_tipo_cambio: 0,
        rel_num_parcialidad: 0,
        rel_imp_pagado: 0,
        rel_imp_saldo_anterior: 0,
        rel_imp_saldo_insoluto: 0,
      },
    ],
    folioSelected: "",
    serieSelected: "",
    serieSelected: "",
    rfcSearch: "",
    guidIncomeSearch: "",
    rfcSearchIn: "",
    guidIncomeSearchIn: "",
    selectedRfc: "",
    dateTs: "",
    showDialogRelacionados: false,
    itemsRel: [],
    verLimpiarFiltros: false,
    tipoDocIn: "Seleccionar Todos",
    itemsSelectComprobante: ["Seleccionar Todos", "Ingreso", "Egreso"],
  }),
  created() {
    this.$store.commit("setIsRetencion", false);
    this.$store.commit("setBlData", null);

    this.$store.commit("setAlertError", false);
    this.errorMessage = "";

    let dataInterval = this.$store.state.dateInterval;

    if (dataInterval === null) return;

    this.verLimpiarFiltros = true;
    this.selectedDates[0] = dataInterval.dateInit;
    this.selectedDates[1] = dataInterval.dateEnd;
    this.dateTs = dataInterval.dateTs;
    this.conPago = this.$store.state.tipoDocTs === "Pago";
    this.$store.commit("setTipoDocTs", "");
    this.$store.commit("setDateInterval", null);

    this.getTotalCount();
  },
  methods: {

    cerrarCrearSolicitud() {
            this.descripcionIn = "";
            this.selectedDatesIn = [];
            this.$refs.dateRange.limpiarFechas();
            this.contextoIn = "Emitidos";
            this.rfcSearchIn = "";
            this.showDialogCrear = false;
        },

    ConsultarExpediente(item){


      var jsonData = {
        token: this.$store.state.token,
        uuid: item.id,
      };
      service
        .api()
        .SearchByGUID(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.itemFindEx = payload;
            this.$refs.exped.ConsultarExpediente(item);
          } else {

            this.alert_errorR = true;
            this.errorMessageR = response.errorMessage;
            setTimeout(() => {
              this.alert_errorR = false;
              this.errorMessageR = "";
            }, 10000);
          }
        });

      
    },

    ConsultarDocsRelacionados(item) {

      let uuids = this.ObtenerGuids(item);


      var jsonData = {
        token: this.$store.state.token,
        parentDate: item.fecha_emision.substr(0, 10),
        uuids: uuids,
      };

      service
        .api()
        .RelacionadosDocs(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.showDialogRelacionados = true;
            this.itemSelectedDocs = item;
            this.itemsRel = JSON.parse(response.payload);
            if (response.payload == "[]") {
              this.showDialogRelacionados = false;

              this.$store.commit("setAlertOk", false);
              this.$store.commit("setAlertError", true);
              this.$store.commit("setAlertMessage", "El o los documentos relacionados a los que se hacen referencia no se encuentran en la base de datos");
            }
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    initGrid: function (grid) {

      for (let i = 0; i < grid.columns.length; i++) {
        grid.autoSizeColumn(i);
        grid.columns[i].width += 20;
      }
      for (let i = 0; i < grid.rows.length; i++) {
        grid.autoSizeRows(i);
      }


      grid.columns[grid.columns.length - 2].width = 120;
      grid.columns[grid.columns.length - 1].width += 80;

    },

    verMasDatos(id) {
      var jsonData = {
        token: this.$store.state.token,
        uuid: id,
      };
      service
        .api()
        .SearchByGUID(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.itemFind = payload;
            this.openDialog();
          } else {

            this.alert_errorR = true;
            this.errorMessageR = response.errorMessage;
            setTimeout(() => {
              this.alert_errorR = false;
              this.errorMessageR = "";
            }, 10000);
          }
        });

    },

    openDialog() {
      this.$refs.dialog.showDialogGuid = true;
    },

    ObtenerEstado(estado) {
      let est = "";
      switch (estado) {
        case 'NP':
          est = "No Pagado";
          break;
        case 'P':
          est = "Pagado";
          break;
        case 'SP':
          est = "Sobre Pagado";
          break;

      }

      return est;
    },
    CleanFilters() {
      this.dateTs = "";
      this.verLimpiarFiltros = false;
    },
    getColor(estado) {
      if (estado === "P") return "pagadas";
      else if (estado === "NP") return "nopagadas";
      else if (estado === "SP") return "sobrepagadas";
    },

    calcularDiferencia(item) {
      if (item.estado === 'P')
        return Number(item.totalmxn - item.importe_pagado_mxn);
      else
        return "";
    },

    calculatePendiente(item) {
      if (item.estado === 'P')
        return 0;

     // if (Number(item.totalmxn - item.importe_pagado_mxn) <= 0)
      //  return 0;

      return Math.abs(Number(item.totalmxn - item.importe_pagado_mxn));
    },
    retrieveXML(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: this.contexto.substring(0, 1),
        uuid: item.id,
        issuedDate: item.fecha_emision,
      };
      service
        .api()
        .RetrieveXML(jsonData)
        .then((response) => {
          if (response.result) {
            let urlXML = response.payload;
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              window.open(urlXML, "_blank");
            } else {
              helpers.openNewBackgroundTab(urlXML);
            }
            setTimeout(() => {
              this.$store.commit("setOverlay", false);
            }, 1000);
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    retrievePDF(item) {
      this.$store.commit("setOverlay", true);
      let par = "";
      if (this.esRecibido) par = "R||" + item.rfc;
      else par = "E||";
      let parameters = par + "|" + item.id + "|" + item.fecha_emision;
      let urlPDF = "";

      this.$store.commit("setAlertError", false);
      this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);

        this.$store.commit("setOverlay", false);
      }, 2000);
    },
    retrievePagoXML(item) {
      var jsonData = {
        token: this.$store.state.token,
        context: this.esEmitido ? "E" : "R",
        uuid: item.cfdi_pago_folio_fiscal,
        issuedDate: item.cfdi_pago_fecha_emision,
      };
      service
        .api()
        .RetrieveXML(jsonData)
        .then((response) => {
          if (response.result) {
            let urlXML = response.payload;
            this.$store.commit("setOverlay", true);
            if (navigator.userAgent.indexOf("Firefox") > -1) {
              window.open(urlXML, "_blank");
            } else {
              helpers.openNewBackgroundTab(urlXML);
            }
            setTimeout(() => {
              this.$store.commit("setOverlay", false);
            }, 1000);
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    retrievePagoPDF(item) {
      this.$store.commit("setOverlay", true);
      let par = "";
      if (this.esRecibido) par = "R||" + this.selectedRfc;
      else par = "E||";
      let parameters =
        par +
        "|" +
        item.cfdi_pago_folio_fiscal +
        "|" +
        item.cfdi_pago_fecha_emision;
      let urlPDF = "";

      this.$store.commit("setAlertError", false);
      this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);
        this.okMessage = "";
        this.$store.commit("setOverlay", false);
      }, 2000);
    },
    retrievePDFPago(item, pago) {
      this.$store.commit("setOverlay", true);
      let par = "";
      if (this.esRecibido) par = "R||" + this.selectedRfc;
      else par = "E||";
      let parameters = par + "|" + pago.id + "|" + pago.fecha_emision;
      let urlPDF = "";

      this.$store.commit("setAlertError", false);
      this.$store.commit("setAlertOk", true);
      this.$store.commit("setAlertMessage", "Un momento por favor. Estamos obteniendo la información en formato PDF.");

      urlPDF =
        service.api().MysuiteBaseUrl() +
        "/CFDI/PDF?parameters=" +
        helpers.Base64Encode(parameters) +
        "&token=" +
        helpers.Base64Encode(this.$store.state.token);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(urlPDF, "_blank");
      } else {
        helpers.openNewBackgroundTab(urlPDF);
      }
      setTimeout(() => {
        this.$store.commit("setAlertOk", false);

        this.$store.commit("setOverlay", false);
      }, 2000);
    },
    retrieveReporte(ruta) {
      this.$store.commit("setOverlay", true);
      if (navigator.userAgent.indexOf("Firefox") > -1) {
        window.open(ruta, "_blank");
      } else {
        helpers.openNewBackgroundTab(ruta);
      }
      setTimeout(() => {
        this.$store.commit("setOverlay", false);
      }, 1000);
    },
    verPagos(item) {
      var jsonData = {
        token: this.$store.state.token,
        uuid_rel: item.id,
        issuedDate: item.fecha_emision.substr(0, 10),
      };
      service
        .api()
        .IncomePagosyRel(jsonData)
        .then((response) => {
          if (response.result) {
            this.folioSelected = item.folio;
            this.serieSelected = item.serie;
            this.itemSelected = JSON.parse(response.payload);
            this.showDialog = true;
            this.selectedRfc = item.rfc;
          } else {
            this.$store.commit("setOverlay", false);
          }
        });
    },
    exportExcel() {
      var headers = {
        RfcPar: this.esEmitido ? "RFC Emisor" : "RFC Receptor",
        Rfc: this.esEmitido ? "RFC Receptor" : "RFC Emisor",
        Nombre: this.esEmitido
          ? "Razon Social Receptor"
          : "Razon Social Emisor",
        UUID: "Folio Fiscal",
        Tipo: "Contexto",
        TipoComprobante: "Tipo Comprobante",
        FechaEmision: "Fecha Emisión",
        Serie: "Serie",
        Folio: "Folio",
        MetodoPago: "Método Pago",
        Moneda: "Moneda",
        SubTotal: "Subtotal",
        TipoCambio: "Tipo Cambio",
        Total: "Total",
        TotalMXN: "Total MXN",
        RetencionISR: "Retención ISR",
        RetencionIVA: "Retención IVA",
        RetencionIEPS: "Retención IEPS",
        TrasladoIVA: "Traslado IVA",
        TrasladoIEPS: "Traslado IEPS",
       // CFDIRel: "CFDIs Relacionados",
        ImportePagado: "Importe Pagado MXN",
        CantidadPagos: "Cantidad Pagos",
        Pendiente: "Pendiente",
        EsRel: "Es Relacionado",
        FolioFiscalP: "Folio Fiscal Pago",
        FechaEmisionPago: "Fecha Emisión",
        FechaPago: "Fecha de Pago",
        MonedaP: "Moneda Pago",
        TipoCambioP: "Tipo Cambio Pago",
        MontoP: "Monto Pago",
        ImporteP: "Importe Pagado",
        Diferencia: "Diferencia Cambiaria",
        Cancelado: "Cancelado"       
      };

      var itemsFormatted = [];

      this.csvData.forEach((item) => {
        let par = item.particion.split("-");
        var pagosJson = JSON.parse(item.pagos);
        let contentRow = {
          RfcPar: helpers.ConvertToEmpty(par[0]),
          Rfc: helpers.ConvertToEmpty(item.rfc),
          Nombre: helpers.ConvertToEmpty(item.nombre),
          UUID: helpers.ConvertToEmpty(item.id),
          Tipo: this.esEmitido ? "Emitido" : "Recibido",
          TipoComprobante: helpers.ConvertToEmpty(
            this.getTipoComprobante(item.tipo_comprobante)
          ),
          FechaEmision: helpers.ConvertToEmpty(item.fecha_emision),
          Serie: helpers.ConvertToEmpty(item.serie),
          Folio: helpers.ConvertToEmpty(item.folio),
          MetodoPago: helpers.ConvertToEmpty(item.metodo_pago),
          Moneda: helpers.ConvertToEmpty(item.moneda),
          SubTotal: helpers.ConvertToEmpty(item.subtotal),
          TipoCambio: helpers.ConvertToEmpty(item.tipo_cambio),
          Total: helpers.ConvertToEmpty(item.total),
          TotalMXN: helpers.ConvertToEmpty(item.totalmxn),
          RetencionISR: helpers.ConvertToEmpty(item.retencion_isr),
          RetencionIVA: helpers.ConvertToEmpty(item.retencion_iva),
          RetencionIEPS: helpers.ConvertToEmpty(item.retencion_ieps),
          TrasladoIVA: helpers.ConvertToEmpty(item.traslado_iva),
          TrasladoIEPS: helpers.ConvertToEmpty(item.traslado_ieps),
          //CFDIRel: helpers.ConvertToEmpty(this.ObtenerGuids2(item)),
          ImportePagado: helpers.ConvertToEmpty(item.importe_pagado_mxn),
          CantidadPagos: helpers.ConvertToEmpty(item.cantidad_pagos),
          Pendiente: helpers.ConvertToEmpty(
            this.currencyFormat(this.calculatePendiente(item))
          ),
          EsRel: item.es_relacionado ? "Si" : "No",
        };

        if (pagosJson.length > 0) {
          var pagosElements = contentRow;
          let i = 1;
          let obtPago = [];
          pagosJson.forEach(function (element) {
            var pagoE = {
              FolioFiscalP: element.id,
              FechaEmisionPago: element.fecha_emision,
              FechaPago: element.fecha_pago,
              MonedaP: element.moneda_p,
              TipoCambioP: element.tipo_cambio_p,
              MontoP: element.monto,
              ImporteP: element.imp_pagado,
              Diferencia: element.diferencia_cambiaria,
              Cancelado: element.cancelado
            };
            pagosElements = {
              ...pagosElements,
              ...pagoE,
            };
            itemsFormatted.push(pagosElements);
          });
        } else {
          itemsFormatted.push(contentRow);
        }
      });

      this.csvDataT = itemsFormatted;
      var fileTitle =
        "Listado_CFDI_ComprobantesPago_" +
        helpers.dateNowCSV().replace(/-/g, "_");
      helpers.exportCSVFile(headers, itemsFormatted, fileTitle);
    },
    start() {
      this.offest = 0;
      this.goPage = this.noPagina = 1;
      this.limit = this.pageSize;
      this.getReporteItems();
    },
    previous() {
      if (this.noPagina === 1) return;

      this.limit = this.pageSize;
      this.noPagina--;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;
      this.getReporteItems();
    },
    next() {
      if (this.noPagina === Math.ceil(this.totalDocumentos / this.pageSize))
        return;

      this.limit = this.pageSize;
      if (this.noPagina + 1 === this.totalPaginas) {
        let res = this.totalDocumentos % this.pageSize;
        if (res !== 0) this.limit = res;
      }
      this.noPagina++;
      this.goPage = this.noPagina;
      this.offest = (this.noPagina - 1) * this.pageSize;

      this.getReporteItems();
    },
    end() {
      this.limit = this.pageSize;
      let res = this.totalDocumentos % this.pageSize;
      if (res !== 0) this.limit = res;

      this.goPage = this.noPagina = this.totalPaginas;
      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
      this.getReporteItems();
    },
    goToPage() {
      this.limit = this.pageSize;
      let actualPage = this.noPagina;
      this.noPagina = parseInt(this.goPage);

      this.offest = (this.noPagina - 1) * this.pageSize;
      let noPaginas = Math.ceil(this.totalDocumentos / this.pageSize);

      if (this.noPagina > 0 && this.noPagina <= noPaginas)
        this.getReporteItems();
      else this.noPagina = this.goPage = actualPage;
    },
    changePagination() {
      this.getReport();
    },
    currencyFormat(value) {
      return helpers.formatMoney(value);
    },
    currencyFormat6(value) {
      return helpers.formatMoney(value, 6);
    },
    handleDate() {
      this.limit = this.pageSize;
      this.offest = 0;
      this.noPagina = 1;
      this.$refs.menu.save(this.selectedDates[0]);
      this.getReporteItems();
    },
    getReport() {
      this.goPage = 1;
      this.offest = 0;
      this.limit = this.pageSize;
      this.noPagina = 1;
      this.$store.commit("setAlertError", false);
      this.errorMessage = "";
      this.getTotalCount();
    },
    ConfirmarEliminarReporte(item) {
      this.eliminarId = item.id;
      this.itemDesc = item.description;
      this.showDialogEliminarReport = true;
    },
    EliminarReporte() {
      var jsonData = {
        id: this.eliminarId,
        token: this.$store.state.token,
      };
      this.showDialogEliminarReport = false;
      service
        .api()
        .ReporteIncomeDeleteReport(jsonData)
        .then((response) => {
          if (response.result) {
            this.alert_okIn = true;
            this.okMessageIn = "Reporte Eliminado Correctamente";
            setTimeout(() => {
              this.alert_okIn = false;
              this.okMessageIn = "";
            }, 10000);
            if (this.itemsGrandes.length == 1)
              this.itemsGrandes = [];
            else
              this.ObtenerReportes();
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    DescargarReporte(itemId) {
      var jsonData = {
        id: itemId,
        token: this.$store.state.token,
      };

      service
        .api()
        .ReporteIncomeRetrieveReport(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.retrieveReporte(response.payload);
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    ObtenerGuids2(item) {
      let uuids = [];

      if (item.cfdi_relacionados) {

        item.cfdi_relacionados.forEach(function (element) {
          uuids = [...uuids, ...element.uuids];
        });
      }
      else {
        uuids = item.uuids || []; // Si item.uuids es undefined, asigna un arreglo vacío
      }

      return uuids.join('|'); // Retorna los UUIDs unidos por '|'
    },
    ObtenerGuids(item) {
      let uuids = [];

      if (item.cfdi_relacionados) {

        item.cfdi_relacionados.forEach(function (element) {
          uuids = [...uuids, ...element.uuids];
        });
      }
      else {
        uuids = item.uuids;
      }

      return uuids;
    },

    ObtenerReportes() {
      var jsonData = {
        token: this.$store.state.token,
      };
      this.showDialogReports = true;
      service
        .api()
        .ReporteIncomeRetrieveGrandes(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);

            let payload = JSON.parse(response.payload);
            this.itemsGrandes = payload;

          } else {

            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }
        });
    },
    requestItems() {
      let statusPago = "";
      this.showDialogCrear = false;
      switch (this.tipoPagoIn) {
        case "Pagado":
          statusPago = "P";
          break;
        case "No Pagado":
          statusPago = "NP";
          break;
        case "Sobre Pagado":
          statusPago = "SP";
          break;
        default:
          statusPago = "";
      }
      var jsonData = {
        description: this.descripcionIn,
        token: this.$store.state.token,
        dateInit: this.selectedDatesIn[0],
        dateEnd: this.selectedDatesIn[1],
        context: this.contextoIn.substring(0, 1),
        paymentStatus: statusPago,
        docType: this.getTipoDocIn(),
        taxid: this.rfcSearchIn,
        uuid: this.guidIncomeSearchIn,
        paymentMethod: this.metodoPagoIn,
      };
      this.showDialogReports = false;
      service
        .api()
        .ReporteIncomeGrande(jsonData)
        .then((response) => {
          this.cerrarCrearSolicitud();
          if (response.result) {
            this.alert_okIn = true;
            this.okMessageIn = "Reporte Solicitado Correctamente";
            setTimeout(() => {
              this.alert_okIn = false;
              this.okMessageIn = "";
            }, 10000);
          } else {
            this.alert_errorIn = true;
            this.errorMessageIn = response.errorMessage;
            setTimeout(() => {
              this.alert_errorIn = false;
              this.errorMessageIn = "";
            }, 10000);
          }


          this.selectedDatesIn[0] = "";
          this.selectedDatesIn[1] = "";
          this.rfcSearchIn = "";
          this.guidIncomeSearchIn = "";
          this.metodoPagoIn = "";
          this.descripcionIn = "";
          this.showDialogReports = true;
        });


    },
    getReporteItems() {
      this.selectedRows = [];
      let statusPago = "";

      switch (this.tipoPago) {
        case "Pagado":
          statusPago = "P";
          break;
        case "No Pagado":
          statusPago = "NP";
          break;
        case "Sobre Pagado":
          statusPago = "SP";
          break;
        default:
          statusPago = "";
      }

      this.items = [];
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        context: this.contexto.substring(0, 1),
        paymentStatus: statusPago,
        orderColumn: this.currentOrderColumn,
        order: this.currentOrder,
        offset: this.offest,
        limit: this.limit,
        rfc: this.rfcSearch,
        uuid: this.guidIncomeSearch,
        dateTs: this.dateTs,
        onlyWithPayments: this.conPago,
        paymentMethod: this.metodoPago,
        docType: this.tipoDoc
      };
      service
        .api()
        .ReporteIncome(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.items = payload;

            this.doctosPagina = this.items.length.toString();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getTotalCount() {
      this.items = [];
      let statusPago = "";

      switch (this.tipoPago) {
        case "Pagado":
          statusPago = "P";
          break;
        case "No Pagado":
          statusPago = "NP";
          break;
        case "Sobre Pagado":
          statusPago = "SP";
          break;
        default:
          statusPago = "";
      }
      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        context: this.contexto.substring(0, 1),
        paymentStatus: statusPago,
        rfc: this.rfcSearch,
        uuid: this.guidIncomeSearch,
        dateTs: this.dateTs,
        onlyWithPayments: this.conPago,
        paymentMethod: this.metodoPago,
        docType: this.tipoDoc
      };

      service
        .api()
        .ReporteIncomeCount(jsonData)
        .then((response) => {
          if (response.result) {
            let payload = JSON.parse(response.payload);
            this.totalDocumentos = payload;
            this.totalPaginas = Math.ceil(this.totalDocumentos / this.pageSize);
            this.getReporteItems();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", response.errorMessage);
          }
        });
    },
    getReporteConciliatorioCSV() {
      let statusPago = "";

      switch (this.tipoPago) {
        case "Pagado":
          statusPago = "P";
          break;
        case "No Pagado":
          statusPago = "NP";
          break;
        case "Sobre Pagado":
          statusPago = "SP";
          break;
        default:
          statusPago = "";
      }

      var jsonData = {
        token: this.$store.state.token,
        dateInit: this.selectedDates[0],
        dateEnd: this.selectedDates[1],
        context: this.contexto.substring(0, 1),
        paymentStatus: statusPago,
        rfc: this.rfcSearch,
        uuid: this.guidIncomeSearch,
        dateTs: this.dateTs,
        onlyWithPayments: this.conPago,
        paymentMethod: this.metodoPago,
        docType: this.tipoDoc
      };

      service
        .api()
        .ReporteIncomeCSV(jsonData)
        .then((response) => {
          if (response.result) {
            this.$store.commit("setOverlay", false);
            this.csvData = JSON.parse(response.payload);
            this.exportExcel();
          } else {
            this.$store.commit("setAlertOk", false);
            this.$store.commit("setAlertError", true);
            this.$store.commit("setAlertMessage", "El proceso de exportación a CSV fue fallido.");
          }
        });
    },
    esOrdenamiento(columna) {
      return this.currentOrderColumn === columna;
    },
    sortColumn(columnName) {
      if (this.currentOrderColumn === columnName) {
        if (this.currentOrder === "DESC") {
          this.currentOrder = "ASC";
        } else {
          this.currentOrder = "DESC";
        }
      }
      this.currentOrderColumn = columnName;
      this.getReporteItems();

      this.rfcRClass = false;
      this.serieClass = false;
      this.fechaEClass = false;
      this.rfcEClass = false;
      this.folioClass = false;
      this.monedaClass = false;
      this.tipo_comprobanteClass = false;
      this.metodo_pagoClass = false;
      this.importe_pagado_mxnClass = false;

      switch (columnName) {
        case "receptor.rfc":
          this.rfcRClass = true;
          break;
        case "serie":
          this.serieClass = true;
          break;
        case "nfecha_emision":
          this.fechaEClass = true;
          break;
        case "emisor.rfc":
          this.rfcEClass = true;
          break;
        case "folio":
          this.folioClass = true;
          break;
        case "moneda":
          this.monedaClass = true;
          break;
        case "tipo_comprobante":
          this.tipo_comprobanteClass = true;
          break;
        case "metodo_pago":
          this.metodo_pagoClass = true;
          break;
        case "importe_pagado_mxn":
          this.importe_pagado_mxnClass = true;
          break;
      }
    },
    getTipoComprobante(tipoDoc) {
      let tipo = "";

      switch (tipoDoc) {
        case "I":
          tipo = "Ingreso";
          break;
        case "E":
          tipo = "Egreso";
          break;
        case "T":
          tipo = "Traslado";
          break;
        case "P":
          tipo = "Pago";
          break;
      }
      return tipo;
    },
    getTipoDocIn() {
      let firstLetter = this.tipoDocIn.substr(0, 1);
      return firstLetter === "S" ? "" : firstLetter;
    },


    parseDocType(tipoDoc) {

      return helpers.parseDoctype(tipoDoc);

    }
  },
  computed: {
    hasNext() {
      return this.noPagina !== this.totalPaginas;
    },
    hasPrevious() {
      return this.noPagina !== 1;
    },
    esEmitido() {
      return this.contexto.substring(0, 1) === "E";
    },
    esRecibido() {
      return this.contexto.substring(0, 1) === "R";
    },

    esCan() {
      return this.esCancelado === "true";
    },
    showSearchButton() {
      return this.selectedDates.length > 1;
    },
    showCreateButton() {
      return this.selectedDatesIn.length > 0 && this.descripcionIn.length > 0;
    },

    showSearchButtonGUID() {
      return this.guidSearch.length > 0;
    },
    showTotalRel() {
      var dateInitY = Number(this.selectedDates[0].substr(0, 4));
      var dateEndY = Number(this.selectedDates[1].substr(0, 4));
      return dateInitY >= 2021 && dateEndY >= 2021;
    },
    labelRfc() {
      if (this.contexto.substring(0, 1) === "E") return "RFC Receptor";
      else return "RFC Emisor";
    },
    fechaInicial() {
      return helpers.getFechaLimite(false);
    },
    allowIssued() {
      return this.$store.state.allowIssued === true;
    },
    allowReceived() {
      return this.$store.state.allowReceived === true;
    },
    itemsOrigen() {
      let elements = [];
      if (this.allowIssued)
        elements.push("Emitidos");
      if (this.allowReceived)
        elements.push("Recibidos");

      if (!this.allowIssued) {
        this.contexto = "Recibidos";
      }

      return elements;
    }
  },
};
</script>

<style scoped>
.altura {
  vertical-align: top;
  padding-top: 10px;
  height: 40px;
}

.negro {
  font-weight: bold;
}

.ancho {
  width: 35px;
}

.anchoGuia {
  width: 50px;
}

.anchoP {
  width: 100px;
}

.normal {
  font-weight: regular;
}

.tamPagina {
  font-weight: bold;
  font-size: 20px;
}

.borde th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  background-color: #113145;
  color: white;
}

.paddingCard {
  padding-right: 25px;
  padding-left: 15px;
}


.min-width-control {
  min-width: 160px;
}

.min-width-control-xl {
  min-width: 200px;
}


.wj-flexgrid {
  max-height: 600px;
  margin: 10px 0;
}

/* style frozen cells */
.wj-cell.wj-frozen,
.wj-cell.wj-alt.wj-frozen {
  background: rgb(255, 255, 125);
}
</style>
